import React, { useState } from "react";
import Layout from "../layouts/layout";

import bottle from "../images/diabetamina/Diabetamina.png";
import bottle3 from "../images/diabetamina/Diabetamina3.png";
import result1 from "../images/diabetamina/result1.jpg";
import result2 from "../images/diabetamina/result2.jpg";
import result3 from "../images/diabetamina/result3.jpg";
import result4 from "../images/diabetamina/result4.jpg";
import sintoma1 from "../images/diabetamina/sintoma1.png";
import sintoma2 from "../images/diabetamina/sintoma2.png";
import sintoma3 from "../images/diabetamina/sintoma3.png";
import sintoma4 from "../images/diabetamina/sintoma4.png";
import sintoma5 from "../images/diabetamina/sintoma5.png";
import sintoma6 from "../images/diabetamina/sintoma6.png";
import sintoma7 from "../images/diabetamina/sintoma7.png";
import sintoma8 from "../images/diabetamina/sintoma8.png";
import sara from "../images/diabetamina/especialista_sara.png";
import testimonialOne from "../images/diabetamina/testimonial_1.jpg";
import testimonialTwo from "../images/diabetamina/testimonial_2.jpg";
import Modal from "../components/Modal";
import Form from "../components/diabetamina/DynamicForm";
import CTASection from "../components/diabetamina/CTASection";

// markup
const Campaign = () => {
	const modalRef = React.useRef();
	const [formSent, setFormSent] = useState(false);

	function formJustSent() {
		setFormSent(true);
	}

	const quantity_available = Math.floor(Math.random() * (20 - 3) + 3),
		team_id = 1;

	return (
		<div>
			<Layout title="Diabetamina | Normaliza el nivel de glucosa en la sangre">
				<div className="px-2 pt-32 lg:pt-16 lg:px-0">
					<div
						className="lg:bg-main-diabetamina lg:h-fit"
						style={{
							backgroundRepeat: "repeat, no-repeat",
							backgroundSize: "auto, cover",
						}}
					>
						<div className="flex flex-col items-center justify-center mt-6 sm:mt-0 lg:text-white px-4 lg:px-72 max-w-6xl">
							<h2 className="text-5xl lg:text-7xl font-semibold pb-4 lg:pb-16 lg:pt-4">
								Diabetamina
							</h2>
							<h1 className="text-3xl lg:text-5xl font-semibold uppercase">
								Regula
							</h1>
							<h3 className="text-xl lg:text-2xl">
								El nivel de glucosa en la sangre
							</h3>
							<h4 className="text-lg lg:text-xl text-center font-semibold lg:mb-4 w-96">
								Sin efectos secundarios
								<span className="hidden md:inline">
									, rápida absorción, complementa tu alimentación. Atención
									personalizada de nuestros especialistas durante todo el
									tratamiento
								</span>
							</h4>
						</div>

						<div className="md:max-w-6xl px-4 md:px-40">
							<div className="grid grid-cols-1 lg:grid-cols-3 justify-center">
								<img
									src={bottle}
									className="h-48 w-auto lg:w-72 lg:h-80 lg:mt-12 mx-auto"
									alt="Frasco diabetamina"
								/>

								<div className="lg:col-span-2 lg:mb-12">
									<Form
										product="Diabetamina"
										team_id={team_id}
										onStored={formJustSent}
										sent={formSent}
										type="standalone"
										quantity_available={quantity_available}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="mt-14 mb-2 lg:mb-20 bg-white-abstract">
					<div className="grid grid-cols-2 lg:grid-cols-3 items-center">
						<div className="col-span-2 max-w-4xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16 text-center">
							<h2 className="text-pink-900 uppercase font-bold text-4xl lg:text-6xl mb-1">
								Consulta gratuita
							</h2>
							<h4 className="text-red-600 uppercase font-semibold text-2xl lg:text-4xl">
								Antes de la compra y sin compromisos
							</h4>
							<p className="font-light text-xl lg:text-3xl pt-4">
								Diariamente nuestros especialistas ayudan a más de 200
								consumidores de Diabetamina a guiarlos en el proceso total de su
								tratamiento. Aumentamos la cantidad de especialistas calificados
								y certificados para atender cualquier tipo de consulta antes de
								la compra.
							</p>
							<button
								onClick={() => modalRef.current.openModal()}
								className="bg-yellow-600 hover:bg-yellow-400 py-2 px-3 rounded shadow mt-4 text-lg mb-4 lg:text-2xl font-semibold"
							>
								Obtener el descuento exclusivo para Costa Rica
							</button>
						</div>
						<img
							src={sara}
							className="w-2/3 hidden lg:block"
							alt="Especialista Sara"
						/>
					</div>
				</section>

				<section className="lg:mt-14 mb-20 py-4 lg:py-12">
					<div className="items-center max-w-8xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16">
						<h2 className="text-yellow-500 uppercase text-center font-bold text-4xl mb-4">
							Nuestros resultados
						</h2>
						<div className="flex flex-wrap justify-center gap-4 pt-4">
							<div className="w-72">
								<img src={result1} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Estabilización del nivel de glucosa en la sangre a partir de
									la segunda semana de uso
								</p>
							</div>
							<div className="w-72">
								<img src={result3} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Reduce el gasto de la compra de medicina recetada en un 80%
								</p>
							</div>
							<div className="w-72">
								<img src={result2} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Resultados efectivos y visibles después del primer mes de uso
								</p>
							</div>
							<div className="w-72">
								<img src={result4} className="mx-auto" alt="Resultado" />
								<p className="text-center pt-3 font-light text-lg">
									Especialistas en salud acompañándote en todo tu tratamiento
									24/7
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className="mt-14 mb-20 py-12 bg-white-abstract relative">
					<div className="items-center lg:max-w-6xl px-10 mx-auto sm:px-20 lg:px-32 lg:px-16">
						<h2 className="text-yellow-500 uppercase text-center font-bold text-3xl lg:text-3xl mb-4">
							Con Diabetamina es mucho más fácil
						</h2>
						<img
							src={bottle3}
							className="h-48 mx-auto lg:mx-0 lg:h-80 mt-12 lg:absolute lg:top-20 lg:left-72"
							alt="Frasco diabetamina"
						/>
						<ul className="list-none text-xl lg:text-2xl lg:pl-72">
							<li className="before:content-['✅'] pt-4">
								Acreditado por el ministerio de salud
							</li>
							<li className="before:content-['✅'] pt-4">
								Sin efectos secundarios
							</li>
							<li className="before:content-['✅'] pt-4">
								Universal y efectivo para diabéticos de ambos tipos
							</li>
							<li className="before:content-['✅'] pt-4">
								Asesoramiento permanente de nuestros especialistas
							</li>
							<li className="before:content-['✅'] pt-4">
								100% natural, sin aditivos artificiales
							</li>
						</ul>
					</div>
				</section>

				<section className="py-12 lg:py-16 bg-gold-400/80">
					<div className="max-w-6xl mx-auto">
						<h3 className="text-3xl font-semibold text gray-800 uppercase text-center pb-12">
							Algunos síntomas de la diabetes
						</h3>
						<div className="flex flex-row flex-wrap gap-y-8 gap-x-12 justify-center">
							<div className="w-40">
								<img
									src={sintoma1}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Problemas de ira"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Problemas de ira
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma2}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Piel reseca"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Piel reseca
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma3}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Fatiga"
								/>
								<p className="text-center pt-3 font-light text-lg">Fatiga</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma7}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Aumento desmedido de apetito"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Aumento desmedido de apetito
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma4}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Urgencia frecuente de orinar"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Urgencia frecuente de orinar
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma5}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Problemas sexuales"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Problemas sexuales
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma6}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Visión borrosa"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Visión borrosa
								</p>
							</div>
							<div className="w-40">
								<img
									src={sintoma8}
									className="rounded-full mx-auto border-2 border-gray-800"
									alt="Sed desmesurada"
								/>
								<p className="text-center pt-3 font-light text-lg">
									Sed desmesurada
								</p>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="bg-[url('./images/pink-texture.jpg')] bg-cover bg-center w-full h-fit relative">
						<div className="flex flex-col px-2 mx-auto py-28">
							<div className="font-semibold text-center">
								<h2 className="text-3xl text-gray-800 lg:text-4xl uppercase">
									Diabetamina
								</h2>
								<h3 className="text-2xl lg:text-3xl text-red-700">
									Regula el nivel de glucosa en tu organismo
								</h3>
								<div className="flex justify-center gap-x-4 flex-wrap-reverse">
									<ul className="list-none text-2xl pt-12 font-normal">
										<li className="before:content-['➕'] pt-2">
											Disminuye la fatiga y el cansancio
										</li>
										<li className="before:content-['➕'] pt-2">
											Aliviana la visión borrosa
										</li>
										<li className="before:content-['➕'] pt-2">
											Controla el peso
										</li>
										<li className="before:content-['➕'] pt-2">
											Regula el metabolismo
										</li>
										<li className="before:content-['➕'] pt-2">
											Recupera la libido
										</li>
										<li className="before:content-['➕'] pt-2">
											Normaliza el suministro de sangre en el organismo
										</li>
										<li className="before:content-['➕'] pt-2">
											Elimina el hambre constante
										</li>
										<li className="before:content-['➕'] pt-2">
											Elimina la sed y el apetito constante
										</li>
										<li className="before:content-['➕'] pt-2">
											Mejora tu calidad de vida
										</li>
									</ul>
									<img
										src={bottle3}
										className="hidden sm:block h-60 lg:h-96 mt-12"
										alt="Frasco diabetamina"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="py-12 max-w-7xl mb-8 mx-auto">
					<h3 className="uppercase text-3xl font-bold text-gray-800 text-center pb-4">
						Cuanto antes regulemos el nivel de glucosa menos medicamentos
						tendremos que tomar - ¡Ordena ahora!
					</h3>
					<div className="shadow-gray-600 shadow-lg rounded-lg border-4 border-dashed border-gray-500 p-6">
						<CTASection
							onStored={formJustSent}
							sent={formSent}
							team_id={team_id}
							quantity_available={quantity_available}
							short={true}
						/>
					</div>
				</section>

				<section className="max-w-6xl mx-auto pb-16">
					<h1 className="text-4xl font-bold uppercase text-center">
						<span className="text-pink-500">con</span>{" "}
						<span className="text-yellow-500">Diabetamina</span>{" "}
						<span className="text-pink-500">previene las consecuencias</span>
					</h1>
					<div className="grid grid-cols-1 lg:grid-cols-2 justify-center justify-items-center mt-10 gap-x-8 gap-y-10 mx-4">
						<div className="hidden lg:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50 justify-self-center">
							<div className="py-3 bg-yellow-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Previene la angiopatía
								</h3>
							</div>
							<p className="p-4">
								Gracias a la reducción del nivel de glucosa en la sangre permite
								hacer los vasos sanguíneos más elásticos, para recuperar la
								conducción natural de la sangre en el organismo evitando así
								accidentes cerebrovasculares.
							</p>
						</div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-pink-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Previene la polineuropatía
								</h3>
							</div>
							<p className="p-4">
								Reduce el efecto tóxico de la glucosa excesiva sobre los órganos
								y tejidos del cuerpo, ayudando a prevenir las lesiones en el
								sistema nervioso.
							</p>
						</div>
						<div className="hidden lg:block"></div>
						<div className="hidden lg:block"></div>
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 bg-orange-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Más beneficios para la salud
								</h3>
							</div>
							<p className="p-4">
								Ayuda a prevenir la cetoacidosis asegurando una alimentación
								completa de las células con la glucosa, brindando una
								oportunidad de evitar la intoxicación del organismo. Además,
								recupera el metabolismo y regula los niveles de carbohidratos,
								alivianando la sobrecarga de los riñones para eliminar glucosa
								excesiva.
							</p>
						</div>
					</div>
					<div className="flex justify-center mt-16 mx-4">
						<div className="h-fit shadow-md shadow-gray-600/50">
							<div className="py-3 px-6 bg-gradient-to-tr from-yellow-500 to-pink-500 text-white font-semibold text-xl">
								<h3 className="flex place-content-center text-center">
									Cuida tu salud, mejora tu calidad de vida
								</h3>
							</div>
						</div>
					</div>
				</section>

				<section className="bg-gray-100 pt-20 pb-24 mt-12">
					<div className="max-w-4xl mx-auto">
						<div className="flex flex-col justify-center gap-8 mx-4 mb-8">
							<div className="grid grid-cols-1 lg:grid-cols-2 bg-white">
								<div className="bg-[url('./images/diabetamina/testimonial_1.jpg')] bg-center bg-cover bg-no-repeat hidden lg:block"></div>
								<div className="block lg:hidden">
									<img src={testimonialOne} alt="Nicolás" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Soy paciente de diabetes tipo 2 desde hace años, pero
												los problemas graves comenzaron el último año. Me volví
												impotente en lo correspondiente a la salud masculina.
												Acompañado de fuertes dolores de cabeza, insomnio e
												irritabilidad. Comencé a tomar Diabetamina desde enero
												de 2022 y luego de unos meses puedo recomendarlo a todos
												los que padecen lo que yo sufrí hace meses, que ahora
												felizmente estoy dejando atrás. Me siento ahora mucho
												mejor y tengo una vida más sana.
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Nicolás Hernandez, 62 años, Limón
										</h3>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-1 lg:grid-cols-2 bg-white">
								<div className="bg-[url('./images/diabetamina/testimonial_2.jpg')] bg-center bg-cover bg-no-repeat hidden lg:block"></div>
								<div className="block lg:hidden">
									<img src={testimonialTwo} alt="Laura" className="" />
								</div>
								<div className="py-5 pl-2">
									<div className="flex flex-col pr-8">
										<div className="relative pl-12">
											<svg
												className="absolute left-0 w-10 h-10 text-navy-500 fill-current"
												data-primary="indigo-500"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 100 125"
												data-primary-reset="{}"
											>
												<path d="M30.7 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2C12.7 83.1 5 72.6 5 61.5c0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S30.7 31.6 30.7 42zM82.4 42c0 6.1 12.6 7 12.6 22 0 11-7.9 19.2-18.9 19.2-11.8 0-19.5-10.5-19.5-21.6 0-19.2 18-44.6 29.2-44.6 2.8 0 7.9 2 7.9 5.4S82.4 31.6 82.4 42z"></path>
											</svg>
											<p className="mt-2 text-gray-600 text-lg">
												Fui diagnosticada con diabetes tipo 2 cuando tenía 36
												años, sufría de fuertes dolores de cabeza y un apetito
												descontrolado, quería comer todo el día y llegué a subir
												más de 15 kilos, además, todo me estresaba y siempre me
												encontraba de mal humor. Probé mucha medicación y nada
												daba resultado. Un día una conocida me recomendó
												Diabetamina, al principio estaba un poco escéptica
												porque había probado muchos medicamentos y ninguno me
												funcionaba; pero con Diabetamina fue diferente. Tuve el
												acompañamiento de un especialista en salud que me
												orientó y definitivamente cambió mi vida. Lo recomiendo
												al 100%
											</p>
										</div>

										<h3 className="pl-12 mt-3 font-medium leading-5 text-gray-800 text-lg">
											Laura Quesada, 52 años, Alajuela
										</h3>
									</div>
								</div>
							</div>
						</div>

						<h2 className="font-semibold text-2xl lg:text-4xl text-yellow-800 uppercase text-center mx-4">
							Normalicemos lo antes posible los niveles de glucosa en tu cuerpo,
							para mejorar tu calidad de vida lo más rápido posible
						</h2>

						<div className="flex justify-center">
							<button
								className="bg-red-600 shadow-md rounded-xl text-white px-4 py-2 mt-3 mx-2 lg:mx-0 font-semibold text-xl"
								onClick={() => modalRef.current.openModal()}
							>
								Hacer el pedido con el descuento y el asesoramiento
							</button>
						</div>
					</div>
				</section>

				<section className="pt-20 pb-12 max-w-6xl mx-auto px-3">
					<p className="text-center text-sm">
						Todos nuestros productos se fabrican en Costa Rica en instalaciones
						registradas, cumpliendo siempre con las Buenas Prácticas de
						Fabricación GMP (Good Manufacturing Practice). Prestamos atención en
						todos los pequeños detalles, proporcionando a nuestros clientes la
						mejor calidad con componentes 100% naturales y sin aditivos ni
						conservantes artificiales. Todas nuestras fórmulas han sido probadas
						y verificadas en laboratorios legales y de confianza para asegurar
						que obtendrás los mejores resultados.
						<br />
						<br />
						Aviso: Los productos y la información que se encuentran en este
						sitio no están destinados a reemplazar el tratamiento o consejo
						médico profesional. Estos productos no están destinados a
						diagnosticar, tratar, curar o prevenir ninguna enfermedad. Los
						resultados individuales pueden variar.
					</p>
				</section>

				<button
					className="fixed bottom-0 w-full h-12 bg-yellow-600 hover:bg-yellow-500 cursor-pointer transition-colors flex items-center justify-center text-white font-semibold text-lg uppercase"
					onClick={() => modalRef.current.openModal()}
				>
					<p>¡Haz clic aquí para hacer el pedido con el descuento!</p>
				</button>
			</Layout>
			<Modal ref={modalRef}>
				<CTASection
					onStored={formJustSent}
					sent={formSent}
					team_id={team_id}
					quantity_available={quantity_available}
				/>
			</Modal>
		</div>
	);
};

export default Campaign;
