import * as React from "react";
import Navbar from "./navbar";

const Layout = ({ title, children }) => {
	return (
		<div>
			<title>{title}</title>
			<Navbar />
			<main className="relative mb-5">
				<div className="mx-auto">
					{children}
				</div>
			</main>
		</div>
	);
};

export default Layout;
