import React, { forwardRef, useImperativeHandle } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

const CustomModal = forwardRef((props, ref) => {
	let [isOpen, setIsOpen] = useState(false);

	useImperativeHandle(ref, () => ({
		openModal() {
			setIsOpen(true);
		}
	}))

	function closeModal() {
		setIsOpen(false);
	}

	

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-50 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<div className="max-w-5xl mx-auto">
									{props.children}
								</div>

								<div className="mt-4 flex justify-end gap-3">
									<button
										type="button"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-gray:ring-blue-500"
										onClick={closeModal}
									>
										Cerrar
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
})

export default CustomModal;
