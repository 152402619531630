import * as React from "react";

const date = new Date();
date.setDate(date.getDate() + 2)
const limit_date = date.toLocaleString('es', { day: "2-digit", month: 'long', year: "numeric" })

const Navbar = () => {
	return (
		<div className="w-full h-fit fixed bg-black text-center z-50 py-3">
			<h5 className="text-white">Debido a la alta demanda en los últimos días, contamos con stock limitado en existencia a la fecha de <span className="font-semibold">{limit_date}</span>.</h5>
			<h5 className="text-yellow-400 font-semibold">¡¡PIDE ANTES DE QUE SE AGOTEN!!</h5>
		</div>
	);
};

export default Navbar;
